const SHOW_POPUP_TIME = 5000

/**
 * Add products to cart asynchronously
 */
window.addToCart = form => window.submitFormAsync(form, showMinicart)

/**
 * Remove item from minicart
 * @param {string} url Native Magento getAjaxDeleteUrl()
 */
window.removeFromCart = url => jQuery.post(url, { form_key: window.form_key }).done(showMinicart) && false

/**
 * Temporally show cart popup
 */
const showMinicart = (response, status) => {
  const button = document.getElementById('product-addtocart-button')
  if (status === 'error') {
    updateButtonIconStatus('fa-times', button)

    const minicart = document.getElementById('cart-top')
    const originalCartContent = minicart.innerHTML
    const message = response.responseText

    minicart.innerHTML = `<div class="cart__message">${message}</div>`
    showCartPopup()

    setTimeout(() => {
      minicart.innerHTML = originalCartContent
    }, SHOW_POPUP_TIME + 1000)

    return
  }

  response = JSON.parse(response)
  window.responsee = response
  const buttonIcon = button.querySelector('i')

  updateButtonIconStatus('fa-check', button)
  buttonIcon.classList.remove('fa-circle-o-notch', 'fa-spin')
  updateCartContent(response.content)
  updateCartQty(response.qty)
  showCartPopup()
  updateButtonIconStatus('fa-shopping-cart', button)
}

window.showMinicart = showMinicart

/**
 * Remove item from minicart
 * @param  {string} url Native Magento getAjaxDeleteUrl()
 */
window.removeFromCart = url => {
  jQuery.ajax({
    url,
    data: { form_key: document.querySelector('input[name="form_key"]').value }
  }).done(response => {
    updateCartContent(response.content)
    updateCartQty(response.qty)
  }).always(() => showCartPopup())

  return false // prevent redirect
}

/**
 * Remove item from minicart
 * @param  {string} url Native Magento getAjaxDeleteUrl()
 */
window.removeFromCart = url => {
  jQuery.ajax({
    url,
    data: { form_key: document.querySelector('input[name="form_key"]').value }
  }).done(response => {
    updateCartContent(response.content)
    updateCartQty(response.qty)
  }).always(() => showCartPopup())

  return false // prevent redirect
}

/**
 * Update cart quantity
 * @param {int} qty New quantity of items in cart
 */
const updateCartQty = qty => {
  document.querySelector('.cart__icon .qty').dataset.qty = qty
}

/**
* Update cart content
 * @param  {string} content Html to replace old cart
 */
const updateCartContent = content => {
  document.getElementById('cart').innerHTML = content
}

/**
 * Temporally show cart popup
 */
const showCartPopup = () => {
  if (typeof window.minicartTimer !== 'undefined') {
    clearTimeout(window.minicartTimer)
  }

  const minicart = document.querySelector('.cart__popup')
  const header = document.querySelector('.header')

  minicart.classList.add('active')
  header.classList.add('active')

  window.minicartTimer = setTimeout(() => {
    minicart.classList.remove('active')
    header.classList.remove('active')
  }, SHOW_POPUP_TIME)
}

/**
 * Change button icons to indicate status
 */
const updateButtonIconStatus = (icon, button) => {
  const buttonIcon = button.querySelector('i')
  const buttonLabel = button.querySelector('span')

  buttonIcon.className = ''
  buttonIcon.classList.add('fa', icon)

  setTimeout(() => {
    buttonIcon.className = 'fa'
    buttonLabel.style.display = 'inline-block'
    button.disabled = false
  }, 1000)
}
